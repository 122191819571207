/*
	Dintomte.se bootstrap scss theme
	Author: Jonas Frid
	version: 0.0.1
*/

/* BASE - Base styles, variables, mixins, etc */
@import 'base/_variables.scss';
@import 'base/_mixins.scss';
@import 'base/_normalize.scss';
@import 'base/_base.scss';

/* MODULES - Individual site components */
@import 'modules/_blocks.scss'; 
@import 'modules/_buttons.scss'; 
@import 'modules/_components.scss'; 
@import 'modules/_sections.scss'; 
@import 'modules/_typography.scss'; 

/* LAYOUTS - Page layouts styles */
@import 'layouts/_nav.scss'; 
@import 'layouts/_header.scss'; 
@import 'layouts/_footer.scss'; 

body {
    padding-top: 50px;
//    background-color: #920e1c;
    background-color: peachpuff;
}
.starter-template {
    padding: 40px 15px;
    text-align: center;
}

#introSection {
    display: table;
    width: 100%;
    height: auto;
    padding: 100px 0;
    text-align: center;
    color: white;
//    background: url(../img/intro-bg.jpg) no-repeat bottom center scroll;
    background-color: skyblue;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    
    img {
        width: 100%;
    }
    
    #logopic{
        margin-top: 20px;
    }
    
    #rightside {
        float: right;
    }
}

#xmasLetterScetion {
    display: table;
    width: 100%;
    height: auto;
    padding: 100px 0;
    text-align: center;
    color: black;
//    background: url(../img/xmas-letter-bg.jpg) no-repeat bottom center scroll;
    background-color: lightgoldenrodyellow;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    min-height: 1080px;
    
    #kidsFormDiv {
        background-color: darkgray;
        display: none;
    }
    #adultformDiv {
        background-color: azure;
        display: none;
    }
    
}
#rentASantaSection {
    display: table;
    width: 100%;
    height: auto;
    padding: 100px 0;
    text-align: center;
    color: black;
//    background: url(../img/xmas-letter-bg.jpg) no-repeat bottom center scroll;
    background-color: cornflowerblue;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    min-height: 1080px;
}

//.modal-content {
//    background-image: url(../img/julbrev.jpg) no-repeat center center fixed;
////    -webkit-background-size: cover;
////    -moz-background-size: cover;
////    -o-background-size: cover;
////    background-size: cover;
//}
.modal-body {
    background-image:url("../img/julbrev1.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;

    
}
footer {
    background-color: darkorchid;
}